import { useEffect, useState } from "react";
import SequenceService from "../Services/SequenceService";
import { Link, NavLink, useParams } from "react-router-dom";

import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

import PubSub from 'pubsub-js';


import 'trumbowyg/dist/plugins/colors/trumbowyg.colors';
import SequenceStepOffcanvas from "../Offcanvas/SequenceStepOffcanvas";
import SequenceNav from './SequenceNav';


function SequenceDetail() {

    const { id } = useParams();
    const [show, setShow] = useState(false);

    const onClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let [sequence, setSequence] = useState({
        steps: []
    });

    let [selectedStep, setSelectedStep] = useState({});





    async function getSequenceDetail() {
        let sequence = await SequenceService.getSequenceDetail(id);
        setSequence(sequence);
        if (selectedStep && selectedStep._id) {
            return;
        }
        setSelectedStep(sequence.steps[0]);
    }

    async function updateSequenceStep(step) {
        await SequenceService.updateSequenceStep(step);
    }

    useEffect(() => {
        getSequenceDetail();

        PubSub.subscribe('refresh.sequence.detail', (msg, data) => {
            console.log('refresh.sequence.detail event');
            getSequenceDetail();
        });

    }, []);

    function onStepClick(step) {
        setSelectedStep(step);
        handleShow();
    }

    function renderSteps() {
        return sequence.steps.map((step, index) => (
            <div key={index} className="card card-shadow mb-4">
                <div className="card-header bg-white d-flex align-items-center">
                    <span className="badge text-bg-light btn-circle me-3">
                        <i className="far fa-envelope text-primary"></i>
                    </span>
                    <span className="bold">
                        Day {step.daysWait + 1}: Email #{index + 1}
                    </span>
                </div>
                <div className="card-body sequence-detail-item">
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        className="mx-2"
                        checked={step.isActive}
                        onChange={(e) => {
                            let updatedSequence = { ...sequence };
                            updatedSequence.steps[index].isActive = e.target.checked;
                            setSequence(updatedSequence);
                            updateSequenceStep(step);
                        }}
                        style={{ transform: 'scale(1.5)' }}
                    />
                    <div onClick={() => onStepClick(step)} className="d-flex align-items-center pointer">
                        <span className="mx-2 bold">
                            {step.replyInThread ? 'Same thread' : 'New thread'}
                        </span>

                        <div className="mx-2">
                            <div>{step.subject}</div>
                            <div className="text-muted">{step.textBody}</div>
                        </div>
                    </div>

                    {/*
     <Dropdown>
                        <Dropdown.Toggle variant="muted"
                            className="no-chevron btn-sm" id="dropdown-basic">
                            <i className="fas fa-ellipsis-h"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#action1">Edit</Dropdown.Item>
                            <Dropdown.Item href="#action2">Clone</Dropdown.Item>
                            <Dropdown.Item href="#action3">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
*/}





                </div>
            </div>
        ));
    }

    return (
        <div>



            <SequenceStepOffcanvas
                show={show}
                onClose={onClose}
                selectedStep={selectedStep}
                setSelectedStep={setSelectedStep}
            />


            <SequenceNav />


            <div className="container">
                <div className="sequence-detail-container">




                    {renderSteps()}


                </div>
            </div>


        </div >
    )
}

export default SequenceDetail;
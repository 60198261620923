import { GET_ACCOUNTS_IN_INBOX_WITH_NAMES, GET_ACCOUNTS_WITH_INBOX, GET_MESSAGES_BY_THREAD_ID, INBOX_GET_THREAD_COUNT, INBOX_GET_THREAD_MESSAGES, SEND_EMAIL_COMPOSE, SEND_EMAIL_REPLY, SYNC_THREADS_FROM_GMAIL, SYNC_THREADS_FROM_GMAIL_BY_ACCOUNT } from "../constants";
import Requests from "../requests";

const InboxService = {
    getThreadMessagesForInbox(page = 1, search = '', account = '') {
        return Requests.getWithAuth(`${INBOX_GET_THREAD_MESSAGES}?page=${page}&search=${search}&account=${account}`);
    },
    getThreadCountForInbox(search = '', account = '') {
        return Requests.getWithAuth(`${INBOX_GET_THREAD_COUNT}?search=${search}&account=${account}`);
    },
    getMessagesByThreadId(threadId) {
        return Requests.getWithAuth(GET_MESSAGES_BY_THREAD_ID.replace(':id', threadId));
    },
    getAccountsWithInbox() {
        return Requests.getWithAuth(GET_ACCOUNTS_WITH_INBOX);
    },
    getAccountsInInboxWithNames() {
        return Requests.getWithAuth(GET_ACCOUNTS_IN_INBOX_WITH_NAMES);
    },
    sendEmail(params) {
        return Requests.postWithAuth(SEND_EMAIL_COMPOSE, params);
    },
    sendReplyEmail(params) {
        return Requests.postWithAuth(SEND_EMAIL_REPLY, params);
    },
    normalizePaginationText(page, count) {
        let pageSize = 10;
        let start = (page - 1) * pageSize + 1;
        let end = start + pageSize - 1;
        if (end > count) end = count;
        return `${start}-${end} of ${count}`;
    },
    syncThreadsFromGmail() {
        return Requests.postWithAuth(SYNC_THREADS_FROM_GMAIL, {});
    },
    syncThreadsFromGmailByAccount(account) {
        return Requests.postWithAuth(SYNC_THREADS_FROM_GMAIL_BY_ACCOUNT, { account });
    }


}

export default InboxService; 
import { useEffect, useState } from "react";
import TemplatesService from "../../Services/TemplatesService";
import { useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import TemplateStepOffcanvas from "./TemplateStepOffcanvas";

function TemplateDetail() {

    const [template, setTemplate] = useState({
        name: 'loading...',
        steps: []
    });

    const [show, setShow] = useState(false);
    const [selectedStep, setSelectedStep] = useState({});

    const { id } = useParams();

    useEffect(() => {
        getTemplateDetail();
    }, []);

    async function getTemplateDetail() {
        const _template = await TemplatesService.getTemplateById(id);
        setTemplate(_template);
    }

    function onClose() {
        setShow(false);
    }


    function onStepClick(step) {
        setSelectedStep(step);
        setShow(true);
    }

    function renderSteps() {
        return template.steps.map((step, index) => (
            <div key={index} className="card card-shadow mb-4">
                <div className="card-header bg-white d-flex align-items-center">
                    <span className="badge text-bg-light btn-circle me-3">
                        <i className="far fa-envelope text-primary"></i>
                    </span>
                    <span className="bold">
                        Day {step.daysWait + 1}: Automatic email
                    </span>
                </div>
                <div className="card-body sequence-detail-item">
                    <div onClick={() => onStepClick(step)} className="d-flex align-items-center pointer">
                        <span className="mx-2">A</span>
                        <span className="mx-2 bold">
                            {step.replyInThread ? 'Same thread' : 'New thread'}
                        </span>

                        <div className="mx-2">
                            <div>{step.subject}</div>
                            <div className="text-muted">{step.textBody}</div>
                        </div>
                    </div>

                </div>
            </div>
        ));
    }

    return (
        <div>
            <TemplateStepOffcanvas
                show={show}
                onClose={onClose}
                selectedStep={selectedStep}
                setSelectedStep={setSelectedStep}
            />

            <div className="ulysses-sequences-title">
                <h5 className="bold">Details
                </h5>
            </div>

            <div className="container">
                <div className="sequence-detail-container">



                    {renderSteps()}


                </div>
            </div>

        </div>
    );
}

export default TemplateDetail;

import { useEffect, useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import TemplatesService from "../../Services/TemplatesService";

function TemplatesList() {

    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        getTemplates();
    }, []);

    async function getTemplates() {
        const _templates = await TemplatesService.getTemplates();
        setTemplates(_templates);
    }

    function renderTemplates() {
        return templates.map((template, index) => (
            <div className="list-group" key={`user${index}`}>
                <div to={'/sequences/' + template._id}
                    className="sequence-list-item"
                    aria-current="true">


                    <Link to={'/admin/templates/detail/' + template._id} className="w-100 pointer blank-link">
                        <div className="d-flex w-100 justify-content-between">
                            <p className="mb-1 bold fs-14">
                                {template.name}
                            </p>
                        </div>
                        <small>
                            <span className="text-primary fs-12"> {template.steps.length} steps</span>
                        </small>
                    </Link>


                    <Dropdown>
                        <Dropdown.Toggle variant="muted"
                            className="no-chevron btn-sm" id="dropdown-basic">
                            <i className="fas fa-ellipsis-h"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item>More actions</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>



                </div>

            </div>
        ))
    }

    return (
        <div>
            <div className="ulysses-sequences-title">
                <h5 className="bold">Templates
                    (<small className='text-danger fs-14 mx-1'>{templates.length}</small>)
                </h5>
            </div>

            <div className="ulysses-sequences-container">
                <div className="ulysses-sequences-body pt-2">
                    {renderTemplates()}
                </div>
            </div>



        </div>
    );
}

export default TemplatesList;

import { useEffect, useState } from "react";
import UserService from "../Services/UserService";
import ModalService from "../Services/ModalService";
import PubSub from 'pubsub-js';
import { useNavigate } from 'react-router-dom';


const INDUSTRIES = [
    "Marketing",
    "Technology",
    "Ecommerce",
    "Food & beverage",
    "Entertainment",
    "Internet",
    "Fashion",
    "Finance",
    "Auto"
];

function SelectIndustries() {
    const navigate = useNavigate();


    const [selectedIndustries, setSelectedIndustries] = useState([]);

    useEffect(() => {
        getUserIndustries();
    }, []);

    async function getUserIndustries() {
        let userIndustries = await UserService.getUserIndustries();
        setSelectedIndustries(userIndustries.industries);
    }

    const saveUserIndustries = async () => {
        let userIndustries = await UserService.saveUserIndustries(selectedIndustries);
        if (userIndustries) {
            // Close the modal
            ModalService.closeSelectIndustriesModal();
            PubSub.publish('show-basic-notification', {
                title: 'Industries updated',
                message: `Industries have been updated`
            });

            // Redirect to search
            navigate('/search');

        }
    }
    let renderIndustries = () => {
        return INDUSTRIES.map((industry, index) => {

            let selected = selectedIndustries.indexOf(industry) > -1;

            return (
                <div onClick={(e) => {
                    // Toggle selected class to the div
                    let target = e.target;
                    if (target.tagName !== 'DIV') {
                        target = target.parentElement;
                    }
                    let updatedSelectedIndustries = [...selectedIndustries];

                    if (!target.classList.contains('selected')) {
                        updatedSelectedIndustries.push(industry);

                    } else {
                        let index = updatedSelectedIndustries.indexOf(industry);
                        if (index > -1) {
                            updatedSelectedIndustries.splice(index, 1);
                        }
                    }

                    setSelectedIndustries(updatedSelectedIndustries);

                    target.classList.toggle('selected');
                }}
                    className={'industries-select-box' + (selected ? ' selected' : '')}
                    key={index}>
                    <span className="industries-select-text">{industry}</span>
                    <i className="far fa-check-circle"></i>
                </div>
            )
        })
    }

    return (
        <div>
            <div className="modal fade" id="select-industries-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title bold">
                                Select 3 industries in which you'd be interested in working.
                            </h5>
                            <button type="button" className="btn-close" onClick={() => ModalService.closeSelectIndustriesModal()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="industries-select-container">
                                {renderIndustries()}
                            </div>

                        </div>
                        <div className="modal-footer">
                            {selectedIndustries.length >= 3 ? (
                                <button type="button" onClick={saveUserIndustries} className="btn btn-dark">Save</button>
                            ) : <span></span>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SelectIndustries;
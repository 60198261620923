import { useEffect, useState } from "react";
import UserService from "../Services/UserService";
import PubSub from "pubsub-js";
import { useParams } from "react-router-dom";

function AdminProfile() {

    const { id } = useParams();
    const [profile, setProfile] = useState({});
    const [user, setUser] = useState({});

    useEffect(() => {
        getUserProfileById();
        getUserDetail();
    }, []);

    async function getUserDetail() {
        let user = await UserService.getUserById(id);
        setUser(user);
    }

    async function getUserProfileById() {
        let profile = await UserService.getUserProfileById(id);
        setProfile(profile);
    }

    async function updateProfile() {
        await UserService.updateUserProfileById(id, profile);
        showSuccessUpdateProfileNotification();

    }

    function showSuccessUpdateProfileNotification() {
        PubSub.publish('show-basic-notification', {
            title: 'Success',
            message: `Profile has been updated`
        });
    }


    return (
        <div>

            <div className="card">
                <div className="card-header">
                    Profile info
                </div>

                <div className="card-body">

                    <div className="form-group">
                        <label className="fs-13 bold">Email</label>
                        <input type="text"
                            value={user.email}
                            disabled
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">Full name</label>
                        <input type="text"
                            value={profile.fullname}
                            onChange={(e) => setProfile({ ...profile, fullname: e.target.value })}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">First name</label>
                        <input type="text"
                            value={profile.firstname}
                            onChange={(e) => setProfile({ ...profile, firstname: e.target.value })}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">Gender</label>

                        <select
                            value={profile.gender}
                            onChange={(e) => setProfile({ ...profile, gender: e.target.value })}
                            className="form-control form-control-sm" >
                            <option value=""></option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>


                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">Company</label>
                        <input type="text"
                            value={profile.company}
                            onChange={(e) => setProfile({ ...profile, company: e.target.value })}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">City</label>
                        <input type="text"
                            value={profile.city}
                            onChange={(e) => setProfile({ ...profile, city: e.target.value })}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">Country</label>
                        <input type="text"
                            onChange={(e) => setProfile({ ...profile, country: e.target.value })}
                            value={profile.country}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">Requested work</label>
                        <input type="text"
                            value={profile.requested_work}
                            onChange={(e) => setProfile({ ...profile, requested_work: e.target.value })}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">Background</label>
                        <input type="text"
                            value={profile.background}
                            onChange={(e) => setProfile({ ...profile, background: e.target.value })}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">Profile URL</label>
                        <input type="text"
                            value={profile.profile_url}
                            onChange={(e) => setProfile({ ...profile, profile_url: e.target.value })}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group">
                        <label className="fs-13 bold">Rate</label>
                        <input type="text"
                            value={profile.rate}
                            onChange={(e) => setProfile({ ...profile, rate: e.target.value })}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group mt-4">

                        <button
                            onClick={updateProfile}
                            className="btn btn-primary btn-sm">
                            Save profile
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AdminProfile;
import { GET_TEMPLATE_BY_ID, GET_TEMPLATES_LIST, UPDATE_TEMPLATE_STEP_BY_ID } from "../constants";
import Requests from "../requests";

const TemplatesService = {
    getTemplates,
    getTemplateById,
    updateTemplateStep
}

async function getTemplates() {
    return Requests.getWithAuth(GET_TEMPLATES_LIST);
}

async function getTemplateById(_id) {
    return Requests.getWithAuth(GET_TEMPLATE_BY_ID(_id));
}

async function updateTemplateStep(step_id, step) {
    return Requests.postWithAuth(UPDATE_TEMPLATE_STEP_BY_ID(step_id), step);
}

export default TemplatesService;
import { useState } from "react";
import PubSub from 'pubsub-js'


function LoadingNotification() {

    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('message');

    PubSub.subscribe('loading.notification', (msg, data) => {
        setShow(data.show);
        setMessage(data.message);
    });

    return (
        <div className={`notification basic-notification ${show ? 'active' : ''} d-flex align-items-center`}>
            <div className="me-3"><i className="fas fa-spinner fa-spin"></i></div>
            <div>
                <b className="fs-16">Loading...</b>
                <div className="fs-14">{message}</div>
            </div>

        </div>
    )

}

export default LoadingNotification;
import { useEffect, useState } from "react";
import { MODAL_PREBUILT_TEMPLATES } from "../constants";
import ModalService from '../Services/ModalService';
import SequenceService from "../Services/SequenceService";
import EmailSequenceService from '../Services/EmailSequenceService';
import PubSub from 'pubsub-js';
import { useNavigate } from "react-router-dom";

function PrebuiltTemplates({ leadsSelected = [] }) {

    const navigate = useNavigate();

    const [templates, setTemplates] = useState([]);
    const [sequenceCount, setSequenceCount] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState({ steps: [] });

    const [loading, setLoading] = useState(false);


    async function getTemplates() {
        let data = await SequenceService.getSequenceTemplates();
        setTemplates(data);
        let selectedTemplate = data[0];
        setSelectedTemplate(selectedTemplate);
    }

    useEffect(() => {
        getTemplates();
        getSequencesCountByCurrentUser()
    }, []);

    async function getSequencesCountByCurrentUser() {
        let { count } = await SequenceService.getSequenceCount();
        setSequenceCount(count);
    }


    function closeModal() {
        ModalService.closePrebuiltTemplatesModal();
    }

    async function createNewSequence() {
        setLoading(true);

        if (sequenceCount > 0) {
            // Redirect to sequences page
            PubSub.publish('show-basic-notification', {
                title: 'Sequence created',
                message: `Sequence has been created`
            });

            setLoading(false);
            closeModal();

            navigate('/sequences');
            return;
        }



        let { _id } = await SequenceService.createSequenceFromTemplate(selectedTemplate._id);

        let data = Array(5000).fill().map((_, idx) => ({ _id: `placeholder-${idx}` }));

        await EmailSequenceService.createEmailSequenceFromLeadsIdsUsingPlaceholder({
            leads_id: data.map(lead => lead._id),
            sequence_id: _id
        });

        closeModal();

        // Redirect to sequences page
        PubSub.publish('show-basic-notification', {
            title: 'Sequence created',
            message: `Sequence has been created`
        });

        setLoading(false);

        navigate('/sequences');



    }


    function renderTemplatesSidebar() {
        return templates.map((template, index) => (
            <div
                key={'template-' + index}
                onClick={() => setSelectedTemplate(template)}
                className={'ulysses-prebuilt-sidebar-item ' + (template._id === selectedTemplate._id ? 'active' : '')} >
                {template.name}
            </div>
        ));
    }

    function renderSteps() {
        return <div className="accordion accordion-flush mt-2" id="accordionExample">
            {selectedTemplate.steps.map((step, index) => (
                <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} data-bs-parent="#accordionExample" aria-expanded="true" aria-controls={`#collapse${index}`}>
                            <div className="d-flex align-items-center">
                                <span className="badge text-bg-light btn-circle me-3">
                                    <i className="far fa-envelope text-primary"></i>
                                </span>

                                <div className="small" style={{ flex: 1 }}>
                                    <div className="bold fs-14">Email #{index + 1}</div>
                                    <div className="fs-12">Day {index === 0 ? step.daysWait + 1 : step.daysWait}</div>
                                </div>
                            </div>


                        </button>
                    </h2>
                    <div id={`collapse${index}`} className="accordion-collapse collapse show" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className="fs-12 mt-2 ps-5">
                                <div className="bold mb-2">Subject: {step.subject}</div>
                                <div style={{ whiteSpace: 'pre-line' }}>{step.textBody}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    }


    let daysInSequence = 0;

    if (selectedTemplate.steps.length > 0) {
        daysInSequence = selectedTemplate.steps[selectedTemplate.steps.length - 1].daysWait;
    }



    return (

        <div>
            <div className="modal fade"
                id={MODAL_PREBUILT_TEMPLATES}
                tabIndex="-1"
                aria-labelledby={MODAL_PREBUILT_TEMPLATES}
                aria-hidden="true">
                <div className="modal-dialog modal-lg">

                    <div className="modal-content">

                        <div className="modal-header" style={{ borderBottom: 'unset' }}>
                            <div className="bold">Prebuilt templates</div>
                        </div>

                        <div className="d-flex">
                            <div className="ulysses-prebuilt-sidebar">
                                {renderTemplatesSidebar()}
                            </div>

                            <div className="ulysses-prebuilt-content">


                                <h5 className="bold">{selectedTemplate.name}</h5>

                                {renderSteps()}



                            </div>


                        </div>

                        <div className="modal-footer">
                            <button className="btn btn-secondary btn-sm fs-14" onClick={closeModal}>Cancel</button>
                            <button
                                onClick={createNewSequence}
                                disabled={loading}
                                className="btn btn-primary btn-sm fs-14">
                                {loading && (<i className="fa-solid fa-spinner fa-spin me-2"></i>)}
                                {sequenceCount > 0 ? 'View' : 'Start'} campaign
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )

}

export default PrebuiltTemplates;